import * as formik from "formik";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { ReactComponent as FileResetIcon } from "../../../icons/x-circle.svg";
import { ReactComponent as Load } from "../../../icons/load.svg";
import { ReactComponent as File } from "../../../icons/file-icon.svg";
import ErrorTooltip from "../../common/ErrorTooltip";
import * as yup from "yup";
import YupPassword from "yup-password";
import { normalizeFiles } from "../../../utils/normalizeData";
import { useDispatch, useSelector } from "react-redux";
import { addObject, getIsLoading } from "../../../store/mc";
import { openModal } from "../../../store/modal";
import { getCurrentUser, getUsersFetchingStatus } from "../../../store/users";
import { checkChangeDataObject, updatedData } from "../../../utils/changeData";
import { getIsLoadingObject, updateObject } from "../../../store/object";
import { useParams, Link } from "react-router-dom";
import { useState } from "react";
YupPassword(yup);

const AddObject = ({ payload = null }) => {
  const { Formik } = formik;
  const dispatch = useDispatch();
  const isGlobalLoading = useSelector(getUsersFetchingStatus());
  const isLoading = useSelector(getIsLoading());
  const isLoadingObject = useSelector(getIsLoadingObject());
  const user = useSelector(getCurrentUser());
  const { objectId } = useParams();

  const initialValues = payload
    ? {
        title: payload.title,
        typeProject: payload.typeProject,
        address: payload.address,
        profit: String(payload.profit),
        dateStart: String(payload.dateStart),
        owner: payload.owner,
        sale: payload.sale,
        description: payload.description,
        maxInvest: String(payload.maxInvest / 1000000),
        minInvest: String(payload.minInvest / 1000000),
        photo: normalizeFiles(payload.Files).filter(
          (file) => file.fileType === "objectPhoto"
        ),
        document: normalizeFiles(payload.Files).filter(
          (file) => file.fileType === "objectDocs"
        ),
      }
    : {
        title: "",
        typeProject: "",
        address: "",
        maxInvest: "",
        minInvest: "",
        profit: "",
        dateStart: "",
        owner: false,
        sale: true,
        photo: [],
        document: [],
        description: "",
      };

  const [deletedPhotos, setDeletedPhotos] = useState([]);
  const [deletedDocs, setDeletedDocs] = useState([]);
  const [photos, setPhotos] = useState(initialValues.photo);
  const [docs, setDocs] = useState(initialValues.document);

  const schema = yup.object().shape({
    title: yup.string().required("Введите название"),
    typeProject: yup
      .string()
      .required("Укажите тип проекта")
      .min(1, "Укажите тип проекта"),
    address: yup.string().required("Введите адрес"),
    maxInvest: yup
      .string()
      .test(
        "numbers",
        "Допустимы только цифры",
        (value) =>
          !value || /^(0$|-?[1-9]\d*(\.\d*[1-9]$)?|-?0\.\d*[1-9])$/.test(value)
      )
      .required("Введите полный объем"),
    minInvest: yup
      .string()
      .test(
        "numbers",
        "Допустимы только цифры",
        (value) =>
          !value || /^(0$|-?[1-9]\d*(\.\d*[1-9]$)?|-?0\.\d*[1-9])$/.test(value)
      )
      .required("Введите мин.объем"),
    profit: yup
      .string()
      .test(
        "numbers",
        "Допустимы только цифры",
        (value) => !value || /^[0-9]+$/.test(value)
      )
      .required("Введите доходность"),
    dateStart: yup
      .string()
      .test(
        "numbers",
        "Допустимы только цифры",
        (value) => !value || /^[0-9]+$/.test(value)
      )
      .required("Введите сроки запуска"),
    photo: yup
      .mixed()
      .test("arr", "Загрузите файлы", (value) => value && value.length > 0),
    document: yup
      .mixed()
      .test("arr", "Загрузите файлы", (value) => value && value.length > 0),
    description: yup.string().required("Введите описание"),
  });

  const handleAdd = (data) => {
    return dispatch(addObject(data));
  };

  const handleUpdate = async (values) => {
    if (payload.status === "approved") {
      await dispatch(
        openModal({
          body: {
            item: {
              objectId,
              data: {
                ...updatedData(values, initialValues),
                deletedPhotos,
                deletedDocs,
              },
            },
            result: "",
            text: "При изменении одобренного объекта он отправится на переодобрение. Продолжить?",
          },
          type: "confirm",
        })
      );

      setDeletedPhotos([]);
      setDeletedDocs([]);
    } else {
      dispatch(
        updateObject({
          objectId,
          data: {
            ...updatedData(values, initialValues),
            deletedPhotos,
            deletedDocs,
          },
        })
      );
      setDeletedPhotos([]);
      setDeletedDocs([]);
    }
  };

  const checkData = (values) => {
    return checkChangeDataObject(values, initialValues);
  };

  if (!isLoading && user && !isLoadingObject)
    return (
      <div className="container-scroll p-2">
        <div className="container-list">
          <Formik
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={schema}
            onSubmit={
              !payload
                ? async (values, { resetForm }) => {
                    const isReset = await handleAdd(values);
                    if (isReset) {
                      resetForm();
                      dispatch(
                        openModal({
                          body: "Ваша заявка на регистрацию объекта успешно отправлена",
                          type: "auth",
                        })
                      );
                      document.getElementById("validationFormik08").value = "";
                      document.getElementById("validationFormik09").value = "";
                    }
                  }
                : (values) => handleUpdate(values)
            }
            initialValues={initialValues}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              values,
              errors,
            }) => (
              <Form onSubmit={handleSubmit} className="px-1 bg-white">
                <Row
                  className="m-auto px-4 pt-3 pb-2"
                  style={{ fontSize: "15px" }}
                >
                  <Col className="d-flex justify-content-between align-items-center">
                    {payload ? (
                      <>
                        <h4 className="m-0 ff-heavy-22">
                          Редактирование объекта #{objectId}
                        </h4>
                        <div>
                          <Button
                            variant="primary"
                            className="me-2 p-0"
                            style={{ height: "40px" }}
                          >
                            <Link
                              className="nav-link"
                              to={`/chat/object/${objectId}`}
                              style={{ padding: "6px 8px" }}
                            >
                              Перейти в чат
                            </Link>
                          </Button>

                          <Button
                            type="submit"
                            disabled={!checkData(values)}
                            className="btn-base"
                            style={{ height: "50px" }}
                          >
                            Объект завершен
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <h4 className="m-0 ff-heavy-22">Регистрация объекта</h4>
                        <Button
                          className="btn-base"
                          style={{ height: "50px" }}
                          type="submit"
                        >
                          Объект завершен
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>

                <hr className="m-0" />

                <Row
                  className="m-auto p-4 pt-3"
                  style={{ fontSize: "15px", fontFamily: "Futura PT Demi" }}
                >
                  <Col md="12" lg="5">
                    <Form.Group
                      as={Row}
                      className="mx-0 mb-3 position-relative align-items-end"
                      style={{
                        minHeight: "45px",
                      }}
                    >
                      <Form.Label className="m-auto p-0" column md="6" lg="5">
                        Название объекта:
                      </Form.Label>
                      <Col md="6" lg="7">
                        <Form.Control
                          type="text"
                          name="title"
                          value={values.title}
                          onChange={handleChange}
                          className="input-object"
                          placeholder="Введите название"
                        />

                        <ErrorTooltip children={errors.title} />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mx-0 mb-3 position-relative"
                      controlId="validationFormik01"
                    >
                      <Form.Label className="m-auto p-0" column md="6" lg="5">
                        Тип проекта:
                      </Form.Label>
                      <Col md="6" lg="7">
                        {!payload ? (
                          <Form.Select
                            name="typeProject"
                            value={values.typeProject}
                            onChange={handleChange}
                            className="input-object drop-custom"
                          >
                            {/* <option key="blankChoice" hidden>
                              Выберите тип
                            </option> */}
                            {user.ManagementCompany.typeProject.map(
                              (type, i) => (
                                <option key={i} value={type}>
                                  {type}
                                </option>
                              )
                            )}
                          </Form.Select>
                        ) : (
                          <Form.Select
                            disabled
                            name="typeProject"
                            value={values.typeProject}
                            onChange={handleChange}
                            className="input-object drop-custom"
                          >
                            <option value={payload.typeProject}>
                              {payload.typeProject}
                            </option>
                          </Form.Select>
                        )}

                        <ErrorTooltip children={errors.typeProject} />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mx-0 mb-3 position-relative align-items-end"
                      controlId="validationFormik02"
                      style={{
                        minHeight: "45px",
                      }}
                    >
                      <Form.Label className="m-auto p-0" column md="6" lg="5">
                        Адрес объекта:
                      </Form.Label>
                      <Col md="6" lg="7">
                        <Form.Control
                          type="text"
                          name="address"
                          value={values.address}
                          onChange={handleChange}
                          className="input-object"
                          placeholder="Введите адрес"
                        />

                        <ErrorTooltip children={errors.address} />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mx-0 mb-3 position-relative align-items-end"
                      controlId="validationFormik03"
                      style={{
                        minHeight: "45px",
                      }}
                    >
                      <Form.Label className="m-auto p-0" column md="6" lg="5">
                        Полный объем инвестирования (млн):
                      </Form.Label>
                      <Col md="6" lg="7">
                        <Form.Control
                          type="text"
                          name="maxInvest"
                          value={values.maxInvest}
                          placeholder="Введите сумму"
                          onChange={handleChange}
                          className="input-object"
                        />
                        <ErrorTooltip children={errors.maxInvest} />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mx-0 mb-3 position-relative align-items-end"
                      controlId="validationFormik04"
                      style={{
                        minHeight: "45px",
                      }}
                    >
                      <Form.Label className="m-auto p-0" column md="6" lg="5">
                        Мин.объем инвестирования (млн):
                      </Form.Label>
                      <Col md="6" lg="7">
                        <Form.Control
                          type="text"
                          name="minInvest"
                          placeholder="Введите сумму"
                          value={values.minInvest}
                          onChange={handleChange}
                          className="input-object"
                        />
                        <ErrorTooltip children={errors.minInvest} />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mx-0 mb-3 position-relative align-items-end"
                      controlId="validationFormik05"
                      style={{
                        minHeight: "45px",
                      }}
                    >
                      <Form.Label className="m-auto p-0" column md="6" lg="5">
                        Ожидаемая доходность (%):
                      </Form.Label>
                      <Col md="6" lg="7" className="align-items-end">
                        <Form.Control
                          type="text"
                          name="profit"
                          value={values.profit}
                          onChange={handleChange}
                          className="input-object"
                          placeholder="Введите сумму"
                        />
                        <ErrorTooltip children={errors.profit} />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mx-0 mb-3 position-relative align-items-end"
                      controlId="validationFormik06"
                      style={{
                        minHeight: "45px",
                      }}
                    >
                      <Form.Label className="m-auto p-0" column md="6" lg="5">
                        Срок запуска проекта (мес):
                      </Form.Label>
                      <Col md="6" lg="7">
                        <Form.Control
                          type="text"
                          name="dateStart"
                          value={values.dateStart}
                          onChange={handleChange}
                          className="input-object"
                          placeholder="Введите срок запуска"
                        />
                        <ErrorTooltip children={errors.dateStart} />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mx-0 mb-3"
                      controlId="validationFormik07"
                    >
                      <Form.Label className="m-auto p-0" column md="6" lg="5">
                        В собственности:
                      </Form.Label>
                      <Col md="6" lg="7">
                        {/* <Form.Check
                            name="owner"
                            type="switch"
                            checked={values.owner}
                            onChange={handleChange}
                          /> */}
                        <label className="toggle-switch">
                          <input
                            type="checkbox"
                            name="owner"
                            checked={values.owner}
                            onChange={handleChange}
                          />
                          <span className="switch" />
                        </label>
                      </Col>
                    </Form.Group>

                    {/* <Form.Group
                        as={Row}
                        className="mx-0 mb-3"
                        controlId="validationFormik11"
                      >
                        <Form.Label className="m-auto p-0" column md="6" lg="5">
                          Возможность продажи:
                        </Form.Label>
                        <Col md="6" lg="7">
                          <Form.Check
                            name="sale"
                            type="switch"
                            checked={values.sale}
                            onChange={handleChange}
                          />
                        </Col>
                      </Form.Group> */}

                    <Form.Group
                      as={Row}
                      className="mx-0 mb-3"
                      controlId="validationFormik08"
                    >
                      <Form.Label className="my-auto p-0" column md="6" lg="5">
                        Фото объекта:
                      </Form.Label>
                      <Col
                        className="position-relative"
                        md="6"
                        lg="7"
                        style={{
                          maxWidth: "330px",
                        }}
                      >
                        <Button
                          variant="btn"
                          className="p-0 position-relative btn-load"
                          style={{ minHeight: "42px" }}
                          title=""
                        >
                          <div
                            className="position-absolute top-50 start-50 translate-middle"
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "13px",
                              color: "#cb9722",
                            }}
                          >
                            <Load /> Загрузить файлы
                            <br />
                            (фото, видео, презентация)
                          </div>
                          <Form.Control
                            type="file"
                            multiple
                            name="photo"
                            accept="image/*, video/*, .pdf"
                            style={{ opacity: "0" }}
                            onChange={(event) => {
                              setPhotos((prevState) => [
                                ...prevState,
                                ...normalizeFiles(event.target.files),
                              ]);
                              setFieldValue("photo", [
                                ...photos,
                                ...normalizeFiles(event.target.files),
                              ]);
                            }}
                          />
                        </Button>
                        <ErrorTooltip children={errors.photo} />
                      </Col>
                    </Form.Group>

                    <Row
                      className="mb-3"
                      style={{
                        minHeight: "60px",
                      }}
                    >
                      <Col md="6" lg="5"></Col>
                      <Col md="6" lg="7">
                        {values.photo.length > 0 &&
                          values.photo.map((photo, index) => (
                            <div
                              title={`${photo.name || photo.originalName}`}
                              key={index}
                              className="d-inline-block position-relative me-1"
                              style={{ minHeight: "46px" }}
                            >
                              <File />
                              {/* {index === values.photo.length - 1 && ( */}
                              <div
                                className="position-absolute ff-demi"
                                style={{
                                  fontSize: "10px",
                                  bottom: "10px",
                                  left: "10px",
                                }}
                              >
                                {photo.type.split("/")[1]}
                              </div>
                              <Button
                                className="position-absolute translate-middle badge rounded-pill border-0"
                                style={{
                                  top: "18%",
                                  right: "-20%",
                                  backgroundColor: "#cb9722",
                                  padding: "0px",
                                }}
                                onClick={() => {
                                  document.getElementById(
                                    "validationFormik08"
                                  ).value = "";
                                  setDeletedPhotos((prevState) => {
                                    return photo?.id
                                      ? [...prevState, photo]
                                      : prevState;
                                  });
                                  setPhotos((prevState) => {
                                    return prevState.filter(
                                      (item, itemIndex) => itemIndex !== index
                                    );
                                  });
                                  setFieldValue(
                                    "photo",
                                    values.photo.filter(
                                      (item, itemIndex) => itemIndex !== index
                                    )
                                  );
                                }}
                              >
                                <FileResetIcon />
                              </Button>
                              {/* )} */}
                            </div>
                          ))}
                      </Col>
                    </Row>
                  </Col>

                  <Col md="12" lg="7">
                    <Form.Group
                      as={Row}
                      className="mx-0 mb-3"
                      controlId="validationFormik09"
                    >
                      <Form.Label className="my-auto p-0" column md="6" lg="3">
                        Юридические документы:
                      </Form.Label>
                      <Col
                        md="6"
                        lg="9"
                        className="position-relative"
                        style={{
                          maxWidth: "330px",
                        }}
                      >
                        <Button
                          variant="btn"
                          className="p-0 position-relative btn-load"
                          style={{ height: "42px" }}
                          title=""
                        >
                          <div
                            className="position-absolute top-50 start-50 translate-middle"
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "13px",
                              color: "#cb9722",
                            }}
                          >
                            <Load /> Загрузить файлы (фото)
                          </div>
                          <Form.Control
                            type="file"
                            multiple
                            name="document"
                            style={{ opacity: "0" }}
                            accept="image/*, .pdf"
                            onChange={(event) => {
                              setDocs((prevState) => [
                                ...prevState,
                                ...normalizeFiles(event.target.files),
                              ]);
                              setFieldValue("document", [
                                ...docs,
                                ...normalizeFiles(event.target.files),
                              ]);
                            }}
                          />
                        </Button>
                        <ErrorTooltip children={errors.document} />
                      </Col>
                    </Form.Group>

                    <Row
                      className="mb-3"
                      style={{
                        minHeight: "60px",
                      }}
                    >
                      <Col md="6" lg="3"></Col>
                      <Col md="6" lg="9">
                        {values.document !== "" &&
                          values.document.map((doc, index) => (
                            <div
                              key={index}
                              className="d-inline-block position-relative my-1 me-1"
                              title={`${doc.name || doc.originalName}`}
                            >
                              <File />
                              <div
                                className="position-absolute ff-demi"
                                style={{
                                  fontSize: "10px",
                                  bottom: "10px",
                                  left: "10px",
                                }}
                              >
                                {doc.type.split("/")[1]}
                              </div>
                              <Button
                                className="position-absolute translate-middle badge rounded-pill border-0 p-0"
                                style={{
                                  top: "18%",
                                  right: "-20%",
                                  backgroundColor: "#cb9722",
                                  padding: "0px",
                                }}
                                onClick={() => {
                                  document.getElementById(
                                    "validationFormik09"
                                  ).value = "";
                                  setDeletedDocs((prevState) => {
                                    return doc?.id
                                      ? [...prevState, doc]
                                      : prevState;
                                  });
                                  setDocs((prevState) => {
                                    return prevState.filter(
                                      (item, itemIndex) => itemIndex !== index
                                    );
                                  });
                                  setFieldValue(
                                    "document",
                                    values.document.filter(
                                      (item, itemIndex) => itemIndex !== index
                                    )
                                  );
                                }}
                              >
                                <FileResetIcon />
                              </Button>
                            </div>
                          ))}
                      </Col>
                    </Row>

                    <Form.Group
                      as={Row}
                      className="mx-0 mb-2 position-relative"
                      controlId="validationFormik10"
                    >
                      <Form.Label className="p-0" column md="6" lg="3">
                        Описание объекта:
                      </Form.Label>
                      <Col md="6" lg="9">
                        <Form.Control
                          as="textarea"
                          type="text"
                          name="description"
                          placeholder="Опишите объект"
                          value={values.description}
                          onChange={handleChange}
                          className="input-object"
                          style={{ height: "300px" }}
                        />
                        <ErrorTooltip children={errors.description} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );

  return (
    <>
      {!isGlobalLoading && (
        <div className="container-scroll">
          <div
            className="d-flex container-form m-auto p-0 pt-2"
            style={{
              width: "calc(100vw - 40px)",
            }}
          >
            <Spinner
              className="justify-content-center m-auto"
              animation="border"
              variant="dark"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AddObject;
