import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import TypeViewButton from "../TypeViewButton";
import { ReactComponent as HideFilter } from "../../../icons/chevron-up.svg";
import { ReactComponent as OpenFilter } from "../../../icons/chevron-down.svg";
import { ReactComponent as ClearFilter } from "../../../icons/trash.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvestStatusesList, getTypeProjectList } from "../../../store/enum";
import * as yup from "yup";
import * as formik from "formik";
import { getMcNames } from "../../../store/mc";
import { getCurrentUser } from "../../../store/users";
import {
  transformToQueryParams,
  transformToFilters,
} from "../../../utils/utilityFunctions";
import { useHistory, useLocation } from "react-router-dom";
import { updateFilterObject } from "../../../store/investment";
import localStorageService from "../../../services/localStorage.service";
import TypeViewButtonFilter from "../TypeViewButtonFilter";

const FilterInvestAdmin = () => {
  const history = useHistory();
  const initialFilters = {
    minInvest: "",
    profit: "",
    dateStart: "",
    typeProject: "",
    owner: "",
    sale: "",
    companyId: "",
    status: "",
  };
  const [isOpenedFilter, setFilter] = useState(false);
  const [initialValues, setValues] = useState(initialFilters);
  const options = useSelector(getTypeProjectList());
  const investStatuses = useSelector(getInvestStatusesList());
  const mcNames = useSelector(getMcNames());
  const currentUser = useSelector(getCurrentUser());
  const { Formik } = formik;
  const dispatch = useDispatch();
  const location = useLocation();

  const schema = yup.object().shape({
    minInvest: yup
      .string()
      .test(
        "numbers",
        "Допустимы только цифры",
        (value) => !value || /^[0-9]+$/.test(value)
      ),
    profit: yup
      .string()
      .test(
        "numbers",
        "Допустимы только цифры",
        (value) => !value || /^[0-9]+$/.test(value)
      ),
    dateStart: yup
      .string()
      .test(
        "numbers",
        "Допустимы только цифры",
        (value) => !value || /^[0-9]+$/.test(value)
      ),
    typeProject: yup.string(),
    nameCompany: yup.string(),
  });

  const handleClick = () => {
    setFilter(!isOpenedFilter);
  };

  useEffect(() => {
    setValues(() => ({
      ...initialFilters,
      ...transformToFilters(location.search),
    }));

    dispatch(
      updateFilterObject({
        ...initialFilters,
        ...transformToFilters(location.search),
      })
    );
  }, [location.search]);

  const handleUpdate = (values) => {
    history.push(`/investment-request${transformToQueryParams(values)}`);
    // currentUser.role === "admin"
    //   ? history.push(`/investment-request${transformToQueryParams(values)}`)
    //   : history.push(`/my-objects${transformToQueryParams(values)}`);
  };

  return (
    <>
      <Formik
        validateOnBlur={false}
        validationSchema={schema}
        onSubmit={() => handleUpdate(initialValues)}
        initialValues={initialValues}
      >
        {({ handleSubmit, handleChange, handleReset, errors }) => (
          <Form
            onSubmit={handleSubmit}
            className="px-3 text-white"
            style={{
              fontSize: "14px",
              background:
                "linear-gradient(269.05deg, rgba(176, 108, 0, 0.6) 0%, rgba(214, 192, 43, 0.6) 25%, rgba(176, 108, 0, 0.6) 43.5%, rgba(214, 192, 43, 0.6) 79%, rgba(183, 115, 3, 0.6) 100%)",
              margin: `${
                localStorageService.getType() === "cards"
                  ? "0 5px 6px 5px"
                  : "0 0 11px 0"
              }`,
            }}
          >
            {/* classic filter */}
            <div className="form-filter position-relative">
              <Row style={{ minHeight: "64px" }}>
                <Form.Group
                  as={Col}
                  sm="3"
                  className="d-flex align-items-center"
                >
                  <div className="p-2 label-filter">
                    Минимальный объем инвестиций:
                  </div>
                  <Col sm="4">
                    <Form.Control
                      type="text"
                      name="minInvest"
                      value={initialValues.minInvest}
                      onChange={(e) => {
                        handleChange(e);
                        setValues((prevState) => ({
                          ...prevState,
                          minInvest: e.target.value,
                        }));
                      }}
                      className={`input-filter ${
                        errors.minInvest ? "border border-3 border-danger" : ""
                      }`}
                    />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Col}
                  sm="2"
                  className="d-flex align-items-center"
                >
                  <div className="p-2 label-filter">Доходность годовых:</div>
                  <Col sm="3">
                    <Form.Control
                      type="text"
                      name="profit"
                      value={initialValues.profit}
                      onChange={(e) => {
                        handleChange(e);
                        setValues((prevState) => ({
                          ...prevState,
                          profit: e.target.value,
                        }));
                        // handleUpdate({
                        //   ...initialValues,
                        //   profit: e.target.value,
                        // });
                      }}
                      className={`input-filter ${
                        errors.profit ? "border border-3 border-danger" : ""
                      }`}
                    />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Col}
                  sm="2"
                  className="d-flex align-items-center"
                >
                  <div className="p-2 label-filter">
                    Срок запуска проекта (месяцы):
                  </div>
                  <Col sm="4">
                    <Form.Control
                      type="text"
                      name="dateStart"
                      value={initialValues.dateStart}
                      onChange={(e) => {
                        handleChange(e);
                        setValues((prevState) => ({
                          ...prevState,
                          dateStart: e.target.value,
                        }));
                      }}
                      className={`input-filter 
                        ${
                          errors.dateStart
                            ? "border border-3 border-danger"
                            : ""
                        }`}
                    />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Col}
                  sm="3"
                  className="d-flex align-items-center"
                >
                  <div className="p-2 label-filter">Тип проекта:</div>
                  <Col sm="7">
                    <Form.Select
                      name="typeProject"
                      value={initialValues.typeProject}
                      onChange={(e) => {
                        handleChange(e);
                        handleUpdate({
                          ...initialValues,
                          typeProject: e.target.value,
                        });
                      }}
                      className="input-filter drop-custom"
                    >
                      <option value="" style={{ color: "grey" }}>
                        Выбрать...
                      </option>
                      {options.map((option, i) => (
                        <option key={i} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Col}
                  sm="2"
                  className="d-flex justify-content-end align-items-center"
                >
                  <Button
                    variant="link"
                    className="link-light btn-filter"
                    onClick={() => {
                      handleReset();
                      handleUpdate(initialFilters);
                    }}
                  >
                    <ClearFilter />
                  </Button>

                  <TypeViewButtonFilter />

                  <Button
                    variant="link"
                    className="link-light btn-filter"
                    onClick={handleClick}
                  >
                    {!isOpenedFilter && <OpenFilter />}
                    {isOpenedFilter && <HideFilter />}
                  </Button>
                </Form.Group>
              </Row>

              <Row className={isOpenedFilter ? "" : "d-none"}>
                <Row>
                  <hr className="col col-sm-12 mx-2 my-0" />
                </Row>
                <div className="d-flex" style={{ minHeight: "64px" }}>
                  <Form.Group
                    as={Col}
                    sm="3"
                    className="d-flex align-items-center"
                  >
                    <div className="p-2 label-filter">В собственности:</div>
                    <Col sm="4">
                      <Form.Select
                        name="owner"
                        value={initialValues.owner}
                        onChange={(e) => {
                          handleChange(e);
                          handleUpdate({
                            ...initialValues,
                            owner: e.target.value,
                          });
                        }}
                        className="input-filter drop-custom"
                      >
                        <option value="" style={{ color: "grey" }}>
                          Выбрать...
                        </option>
                        <option value="true">Да</option>
                        <option value="false">Нет</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    sm="3"
                    className="d-flex align-items-center"
                  >
                    <div className="p-2 label-filter">Возможность продажи:</div>
                    <Col sm="4">
                      <Form.Select
                        name="sale"
                        value={initialValues.sale}
                        onChange={(e) => {
                          handleChange(e);
                          handleUpdate({
                            ...initialValues,
                            sale: e.target.value,
                          });
                        }}
                        className="input-filter drop-custom"
                      >
                        <option value="" style={{ color: "grey" }}>
                          Выбрать...
                        </option>
                        <option value="true">Да</option>
                        <option value="false">Нет</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>

                  {currentUser.role !== "mc" && (
                    <Form.Group
                      as={Col}
                      sm="3"
                      className="d-flex align-items-center"
                    >
                      <div className="p-2 label-filter">Название УК:</div>
                      <Col sm="6">
                        <Form.Select
                          name="companyId"
                          value={initialValues.companyId}
                          onChange={(e) => {
                            handleChange(e);
                            handleUpdate({
                              ...initialValues,
                              companyId: e.target.value,
                            });
                          }}
                          className="input-filter drop-custom"
                        >
                          <option value="" style={{ color: "grey" }}>
                            Выбрать...
                          </option>
                          {mcNames.map((mc, i) => (
                            <option key={i} value={mc.id}>
                              {mc.nameCompany}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  )}

                  {currentUser.role !== "investor" && (
                    <Form.Group
                      as={Col}
                      sm="3"
                      className="d-flex align-items-center"
                    >
                      <div className="p-2 label-filter">Статус объекта:</div>
                      <Col sm="6">
                        <Form.Select
                          name="status"
                          value={initialValues.status}
                          onChange={(e) => {
                            handleChange(e);
                            handleUpdate({
                              ...initialValues,
                              status: e.target.value,
                            });
                          }}
                          className="input-filter drop-custom"
                        >
                          <option value="" style={{ color: "grey" }}>
                            Выбрать...
                          </option>
                          {investStatuses.map((object, i) => (
                            <option key={i} value={object.value}>
                              {object.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  )}
                </div>
              </Row>

              <Button type="submit" className="d-none"></Button>
            </div>

            {/* adaptive filter */}
            <div className="form-filter-compact">
              <Row style={{ minHeight: "64px" }}>
                <div className="col col-xs-10 my-auto text-white ff-demi-22">
                  Фильтры
                </div>
                <Form.Group
                  as={Col}
                  xs="2"
                  className="d-flex justify-content-end align-items-center"
                >
                  <Button
                    variant="link"
                    className="link-light btn-filter"
                    onClick={() => {
                      handleReset();
                      handleUpdate(initialFilters);
                    }}
                  >
                    <ClearFilter />
                  </Button>

                  <TypeViewButtonFilter />

                  <Button
                    variant="link"
                    className="link-light btn-filter"
                    onClick={handleClick}
                  >
                    {!isOpenedFilter && <OpenFilter />}
                    {isOpenedFilter && <HideFilter />}
                  </Button>
                </Form.Group>
              </Row>

              <Row className={`pb-2 ${isOpenedFilter ? "" : "d-none"}`}>
                <hr className="p-0 m-0 mb-2" />

                <Form.Group
                  as={Col}
                  sm="12"
                  className="d-flex align-items-center"
                  style={{ height: "42px" }}
                >
                  <div className="px-2 col col-sm-8 label-filter">
                    Минимальный объем инвестиций:
                  </div>
                  <Col>
                    <Form.Control
                      type="text"
                      name="minInvest"
                      value={initialValues.minInvest}
                      onChange={(e) => {
                        handleChange(e);
                        setValues((prevState) => ({
                          ...prevState,
                          minInvest: e.target.value,
                        }));
                      }}
                      className={`input-filter ${
                        errors.minInvest ? "border border-3 border-danger" : ""
                      }`}
                    />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Col}
                  sm="12"
                  className="d-flex align-items-center"
                  style={{ height: "42px" }}
                >
                  <div className="p-2 col col-sm-8 label-filter">
                    Доходность годовых:
                  </div>
                  <Col>
                    <Form.Control
                      type="text"
                      name="profit"
                      value={initialValues.profit}
                      onChange={(e) => {
                        handleChange(e);
                        setValues((prevState) => ({
                          ...prevState,
                          profit: e.target.value,
                        }));
                      }}
                      className={`input-filter ${
                        errors.profit ? "border border-3 border-danger" : ""
                      }`}
                    />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Col}
                  sm="12"
                  className="d-flex align-items-center"
                  style={{ height: "42px" }}
                >
                  <div className="p-2 col col-sm-8 label-filter">
                    Срок запуска проекта (месяцы):
                  </div>
                  <Col>
                    <Form.Control
                      type="text"
                      name="dateStart"
                      value={initialValues.dateStart}
                      onChange={(e) => {
                        handleChange(e);
                        setValues((prevState) => ({
                          ...prevState,
                          dateStart: e.target.value,
                        }));
                      }}
                      className={`input-filter ${
                        errors.dateStart ? "border border-3 border-danger" : ""
                      }`}
                    />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Col}
                  sm="12"
                  className="d-flex align-items-center"
                  style={{ height: "42px" }}
                >
                  <div className="p-2 col col-sm-8 label-filter">
                    Тип проекта:
                  </div>
                  <Col>
                    <Form.Select
                      name="typeProject"
                      value={initialValues.typeProject}
                      onChange={(e) => {
                        handleChange(e);
                        handleUpdate({
                          ...initialValues,
                          typeProject: e.target.value,
                        });
                      }}
                      className="input-filter drop-custom"
                    >
                      <option value="" style={{ color: "grey" }}>
                        Выбрать...
                      </option>
                      {options.map((option, i) => (
                        <option key={i} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>

                {currentUser.role !== "mc" && (
                  <Form.Group
                    as={Col}
                    sm="12"
                    className="d-flex align-items-center"
                    style={{ height: "42px" }}
                  >
                    <div className="p-2 col col-sm-8 label-filter">
                      Название УК:
                    </div>
                    <Col>
                      <Form.Select
                        name="companyId"
                        value={initialValues.companyId}
                        onChange={(e) => {
                          handleChange(e);
                          handleUpdate({
                            ...initialValues,
                            companyId: e.target.value,
                          });
                        }}
                        className="input-filter drop-custom"
                      >
                        <option value="" style={{ color: "grey" }}>
                          Выбрать...
                        </option>
                        {mcNames.map((mc, i) => (
                          <option key={i} value={mc.id}>
                            {mc.nameCompany}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                )}

                <Form.Group
                  as={Col}
                  sm="12"
                  className="d-flex align-items-center"
                  style={{ height: "42px" }}
                >
                  <div className="p-2 col col-sm-8 label-filter">
                    В собственности:
                  </div>
                  <Col>
                    <Form.Select
                      name="owner"
                      value={initialValues.owner}
                      onChange={(e) => {
                        handleChange(e);
                        handleUpdate({
                          ...initialValues,
                          owner: e.target.value,
                        });
                      }}
                      className="input-filter drop-custom"
                    >
                      <option value="" style={{ color: "grey" }}>
                        Выбрать...
                      </option>
                      <option value="true">Да</option>
                      <option value="false">Нет</option>
                    </Form.Select>
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Col}
                  sm="12"
                  className="d-flex align-items-center"
                  style={{ height: "42px" }}
                >
                  <div className="p-2 col col-sm-8 label-filter">
                    Возможность продажи:
                  </div>
                  <Col>
                    <Form.Select
                      name="sale"
                      value={initialValues.sale}
                      onChange={(e) => {
                        handleChange(e);
                        handleUpdate({
                          ...initialValues,
                          sale: e.target.value,
                        });
                      }}
                      className="input-filter drop-custom"
                    >
                      <option value="" style={{ color: "grey" }}>
                        Выбрать...
                      </option>
                      <option value="true">Да</option>
                      <option value="false">Нет</option>
                    </Form.Select>
                  </Col>
                </Form.Group>

                {currentUser.role !== "investor" && (
                  <Form.Group
                    as={Col}
                    sm="12"
                    className="d-flex align-items-center"
                    style={{ height: "42px" }}
                  >
                    <div className="p-2 col col-sm-8 label-filter">
                      Статус объекта:
                    </div>
                    <Col>
                      <Form.Select
                        name="status"
                        value={initialValues.status}
                        onChange={(e) => {
                          handleChange(e);
                          handleUpdate({
                            ...initialValues,
                            status: e.target.value,
                          });
                        }}
                        className="input-filter drop-custom"
                      >
                        <option value="" style={{ color: "grey" }}>
                          Выбрать...
                        </option>
                        {investStatuses.map((object, i) => (
                          <option key={i} value={object.value}>
                            {object.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                )}
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FilterInvestAdmin;
