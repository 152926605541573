import { useDispatch, useSelector } from "react-redux";
import { getUsersFetchingStatus } from "../../../store/users";
import TypeTable from "../../common/TypeTable";
import Spinner from "react-bootstrap/Spinner";
import { headers } from "../../common/tableConfig/investmentMc";
import {
  getInvestmentList,
  loadInvestmentListMc,
} from "../../../store/investment";
import { useEffect } from "react";

const McInvestmentList = () => {
  const dispatch = useDispatch();
  const investList = useSelector(getInvestmentList());
  const isGlobalLoading = useSelector(getUsersFetchingStatus());

  useEffect(() => {
    dispatch(loadInvestmentListMc());
  }, []);

  return (
    <div className="container-scroll p-2">
      <div className="container-list">
        {investList ? (
          <TypeTable rows={headers} list={investList} role="object" />
        ) : (
          !isGlobalLoading && (
            <div className="d-flex h-50 justify-content-center align-items-end">
              <Spinner animation="border" variant="dark" />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default McInvestmentList;
