import { useSelector } from "react-redux";
import { getCurrentUser, getIsLoggedIn } from "../../store/users";
import { useHistory } from "react-router-dom";

const Main = () => {
  const isLoggedIn = useSelector(getIsLoggedIn());
  const currentUser = useSelector(getCurrentUser());
  const history = useHistory();

  if (isLoggedIn && currentUser) {
    if (currentUser.role === "admin") history.push("/mc-request");
    if (currentUser.role === "mc") history.push("/add-object");
    if (currentUser.role === "investor") history.push("/my-investments");
  }
  if (!isLoggedIn) {
    history.push("/login");
  }
};

export default Main;
