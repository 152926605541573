import { useDispatch, useSelector } from "react-redux";
import { FILTERS, getObjectList, loadObjectList } from "../../../store/object";
import { getUsersFetchingStatus } from "../../../store/users";
import { useEffect } from "react";
import TypeTable from "../../common/TypeTable";
import Spinner from "react-bootstrap/Spinner";
import { headers } from "../../common/tableConfig/objectMc";

const McRequests = () => {
  const dispatch = useDispatch();
  const objectList = useSelector(getObjectList());
  const isGlobalLoading = useSelector(getUsersFetchingStatus());

  useEffect(() => {
    dispatch(loadObjectList(FILTERS));
  }, []);

  if (objectList) {
    const objectListFiltered = objectList.filter(
      (object) => object.status !== "approved" && object.status !== "rejected"
    );

    return (
      <div className="container-scroll p-2">
        <div className="container-list">
          {objectList ? (
            <TypeTable rows={headers} list={objectListFiltered} role="object" />
          ) : (
            !isGlobalLoading && (
              <div className="d-flex h-50 justify-content-center align-items-end">
                <Spinner animation="border" variant="dark" />
              </div>
            )
          )}
        </div>
      </div>
    );
  }
};

export default McRequests;
