import axios from "axios";
import config from "../config.json";
import localStorageService from "./localStorage.service";
import authService from "./auth.service";

const http = axios.create({
  baseURL: config.SERVERLOCAL_URL + "api/",
});

http.interceptors.request.use(
  async function (config) {
    const expiresDate = localStorageService.getTokenExpiresDate();
    const refreshToken = localStorageService.getRefreshToken();

    if (refreshToken && expiresDate < Date.now()) {
      const { data } = await authService.refresh();

      localStorageService.setTokens(data);
    }

    if (refreshToken) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${refreshToken}`,
      };
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (res) => {
    return res;
  },
  function (error) {
    const expectedErrors =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;

    if (!expectedErrors) {
      console.log(error);
    }
    return Promise.reject(error);
  }
);

const httpService = {
  get: http.get,
  post: http.post,
  postForm: http.postForm,
  put: http.put,
  delete: http.delete,
  patch: http.patch,
};
export default httpService;
