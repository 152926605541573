import localStorageService from "../../../services/localStorage.service";
import { Link } from "react-router-dom";
import { normalizeNumber } from "../../../utils/utilityFunctions";
import Button from "react-bootstrap/Button";

export const headers = [
  {
    header: "Номер заявки",
    body: "id",
    bodyDecorator: (id) =>
      localStorageService.getType() === "cards" ? (
        <div className="p-0 ff-heavy-22">ID: {id}</div>
      ) : (
        <div className="p-2 ff-heavy-link">ID: {id}</div>
      ),
  },
  {
    header: "Объект",
    body: "Object",
    bodyDecorator: (obj) =>
      localStorageService.getType() === "cards" ? (
        <div className="p-0">
          <Link to={`/object/${obj.id}`} className="ff-heavy-22">
            <div>{obj.title}</div>
          </Link>
        </div>
      ) : (
        <div className="p-2">
          <Link to={`/object/${obj.id}`} className="ff-heavy-link">
            <div>{obj.title}</div>
          </Link>
        </div>
      ),
  },
  {
    header: "Мин.объём инвестиций",
    body: "Object",
    bodyDecorator: (obj) => {
      return localStorageService.getType() === "cards" ? (
        <div className="m-0 p-0 ff-heavy" style={{ fontSize: "22px" }}>
          {normalizeNumber(obj.minInvest)} ₽
        </div>
      ) : (
        <div className="p-2 ff-demi">{normalizeNumber(obj.minInvest)} ₽</div>
      );
    },
  },
  {
    header: "Доходность годовых",
    body: "Object",
    bodyDecorator: (obj) => {
      return localStorageService.getType() === "cards" ? (
        <div className="m-0 p-0 ff-heavy-22">{obj.profit} %</div>
      ) : (
        <div className="td-profit ff-heavy-link">{obj.profit} %</div>
      );
    },
  },
  {
    header: "Срок запуска проекта",
    body: "Object",
    bodyDecorator: (obj) => (
      <div className="p-2 pe-0">{obj.dateStart} мес.</div>
    ),
  },
  {
    header: "Тип проекта",
    body: "Object",
    bodyDecorator: (obj) => (
      <Button
        variant="light"
        style={{
          backgroundColor: "transparent",
          border: 0,
          color: "#343C4D",
          cursor: "auto",
          fontSize: `${
            localStorageService.getType() === "cards" ? "14px" : "15px"
          }`,
        }}
        className={`p-2 pe-0 ${
          localStorageService.getType() === "cards" ? "ff-demi" : "ff-medium"
        }`}
      >
        {obj.typeProject}
      </Button>
    ),
  },
  {
    header: "В собственности",
    body: "Object",
    bodyDecorator: (obj) => (
      <div className="p-2 pe-0">{obj.owner ? "Да" : "Нет"}</div>
    ),
  },
  {
    header: "Возможность продажи",
    body: "Object",
    bodyDecorator: (obj) => (
      <div className="p-2 pe-0">{obj.sale ? "Да" : "Нет"}</div>
    ),
  },
  {
    header: "Название УК",
    body: "Object",
    bodyDecorator: (obj) => (
      <div className="p-1 pe-0">
        <Link to={`/mc/${obj.ManagementCompany.id}`} className="ff-heavy-link">
          {obj.ManagementCompany.nameCompany}
        </Link>
      </div>
    ),
  },
  {
    header: "Статус",
    body: "status",
    // bodyDecorator: () => (
    //   <Button
    //     variant="secondary"
    //     className="p-1"
    //     disabled={true}
    //     style={{ fontSize: "15px" }}
    //   >
    //     Узнать подробности
    //   </Button>
    // ),
    bodyDecorator: (status) => {
      if (status === "new")
        return (
          <div
            className={`text-center ${
              localStorageService.getType() === "cards"
                ? "td-status-notbg p-0"
                : "td-status"
            }`}
          >
            Еще не одобрен
          </div>
        );
      if (status === "leaving")
        return (
          <div
            className={`text-center ${
              localStorageService.getType() === "cards"
                ? "td-status-notbg p-0"
                : "td-status"
            }`}
          >
            Отменяется
          </div>
        );
      if (status === "exit")
        return (
          <div
            className={`text-center ${
              localStorageService.getType() === "cards"
                ? "td-status-notbg p-0"
                : "td-status"
            }`}
          >
            Вышел из проекта
          </div>
        );
      if (status === "approved")
        return (
          <div
            className={`text-center ${
              localStorageService.getType() === "cards"
                ? "td-status-notbg p-0"
                : "td-status"
            }`}
          >
            Одобрен
          </div>
        );
      if (status === "rejected")
        return (
          <div
            className={`text-center ${
              localStorageService.getType() === "cards"
                ? "td-status-notbg p-0"
                : "td-status"
            }`}
          >
            Отклонен
          </div>
        );
    },
  },
];
