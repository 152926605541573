import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "../../../App.css";
import * as formik from "formik";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import YupPassword from "yup-password";
import ErrorTooltip from "../../common/ErrorTooltip";
import {
  closeModal,
  getIsModalStatus,
  getTypeModal,
} from "../../../store/modal";
import { updateUserInvestor } from "../../../store/users";
YupPassword(yup);

const ProfileInvestor = ({ currentUser }) => {
  const { email, phone, name, telegram } = currentUser;
  const isActiveModal = useSelector(getIsModalStatus());
  const typeModal = useSelector(getTypeModal());
  const dispatch = useDispatch();

  const { Formik } = formik;
  const initialValues = {
    name: name || "",
    email: email,
    phone: phone,
    telegram: telegram || "",
  };

  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Введите эл. почту")
      .email("Введите эл. почту"),
    phone: yup
      .string()
      .required("Введите номер телефона")
      .password()
      .min(11, "Номер должен начинаться с 8")
      .max(11, "Некорректный номер")
      .minNumbers(11, "Пароль должен состоять из цифр")
      .minSymbols(0)
      .minLowercase(0)
      .minUppercase(0),
  });

  const handleSave = (data) => {
    dispatch(updateUserInvestor(data));
  };

  const handleClose = () => {
    dispatch(closeModal());
  };

  const checkChangeData = (values) => {
    let isChanged = false;
    for (const key in initialValues) {
      if (initialValues[key] !== values[key]) isChanged = true;
    }

    return isChanged;
  };

  return (
    <Modal
      className="d-flex align-items-center justify-content-center ff-demi"
      show={isActiveModal && typeModal === "profile"}
      onHide={handleClose}
      animation={false}
      style={{
        color: "#343c4d",
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            fontFamily: "Futura PT Heavy",
          }}
        >
          Редактирование профиля
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-profile p-4">
        <Formik
          validationSchema={schema}
          onSubmit={async (values) => {
            const isReset = await handleSave(values);
            if (isReset) {
              dispatch(closeModal());
            }
          }}
          initialValues={initialValues}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form
              className="d-flex justify-content-center flex-column"
              onSubmit={handleSubmit}
            >
              <div>
                <Form.Group
                  className="mx-0"
                  as={Row}
                  controlId="validationFormik01"
                >
                  <Form.Label className="m-auto p-0" column sm="3">
                    Ваш ID
                  </Form.Label>
                  <Col>
                    <Form.Control
                      plaintext
                      readOnly
                      defaultValue={`#${currentUser.id}`}
                      style={{ height: "42px", color: "#CB9722" }}
                    />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mx-0 mb-2"
                  controlId="validationFormik02"
                >
                  <Form.Label className="m-auto p-0" column sm="3">
                    Имя
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Введите имя"
                      value={values.name}
                      onChange={handleChange}
                      className="input-object"
                    />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mx-0 mb-2 position-relative"
                  controlId="validationFormik03"
                >
                  <Form.Label className="m-auto p-0" column sm="3">
                    Email
                  </Form.Label>
                  <Col className="position-relative">
                    <Form.Control
                      type="text"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      className="input-object"
                    />
                    <ErrorTooltip children={errors.email} />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mx-0 mb-2"
                  controlId="validationFormik04"
                >
                  <Form.Label className="m-auto p-0" column sm="3">
                    Телефон
                  </Form.Label>
                  <Col className="position-relative">
                    <Form.Control
                      type="text"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      className="input-object"
                    />
                    <ErrorTooltip children={errors.phone} />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mx-0 mb-2"
                  controlId="validationFormik05"
                >
                  <Form.Label className="m-auto p-0" column sm="3">
                    Telegram
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      name="telegram"
                      placeholder="Введите @telegram"
                      value={values.telegram}
                      onChange={handleChange}
                      className="input-object"
                    />
                  </Col>
                </Form.Group>
              </div>

              <Button
                className="m-auto my-4 btn-base"
                type="submit"
                disabled={!checkChangeData(values)}
                style={{ width: "230px", height: "62px" }}
              >
                Сохранить
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ProfileInvestor;
