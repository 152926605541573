import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import {
  getCurrentUser,
  getIsLoggedIn,
  getUsersFetchingStatus,
} from "../../store/users";

const InvestorRoute = ({ component: Component, children, ...rest }) => {
  const isLoggedIn = useSelector(getIsLoggedIn());
  const isLoading = useSelector(getUsersFetchingStatus());
  const currentUser = useSelector(getCurrentUser());

  if (!isLoading) {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (!isLoggedIn || currentUser?.role !== "investor") {
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          }
          return Component ? <Component {...props} /> : children;
        }}
      />
    );
  } else {
    return (
      <div
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          backgroundColor: "grey",
          opacity: "0.5",
          zIndex: "10000",
        }}
      >
        <div
          style={{
            position: "relative",
            top: "50%",
            left: "50%",
          }}
        >
          <Spinner animation="border" variant="dark" />
        </div>
      </div>
    );
  }
};

export default InvestorRoute;
