import { createSlice } from "@reduxjs/toolkit";
import authService from "../services/auth.service";
import history from "../utils/history";
import localStorageService from "../services/localStorage.service";
import userService from "../services/user.service";
import config from "../config.json";

const initialState = localStorageService.getRefreshToken()
  ? {
      entities: null,
      isFetching: true,
      error: null,
      isLoggedIn: false,
      dataLoaded: false,
      updated: false,
    }
  : {
      entities: null,
      isFetching: false,
      error: null,
      isLoggedIn: false,
      dataLoaded: false,
      updated: false,
    };

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    authRequested: (state) => {
      state.error = null;
      state.isLoggedIn = false;
      state.isFetching = true;
    },
    authRequestSuccess: (state, action) => {
      state.isFetching = false;
      state.isLoggedIn = true;
    },
    signupRequested: (state) => {
      state.error = null;
      state.isLoggedIn = false;
      state.isFetching = true;
    },
    signupRequestSuccess: (state, action) => {
      state.isFetching = false;
    },
    authRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isFetching = false;
    },
    passwordRequested: (state, action) => {
      state.error = null;
      state.isFetching = true;
    },
    passwordRequestSuccess: (state, action) => {
      state.isFetching = false;
    },
    passwordRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isFetching = false;
    },
    userLogout: (state) => {
      state.entities = null;
      state.isFetching = false;
      state.error = null;
      state.isLoggedIn = false;
      state.dataLoaded = false;
    },
    userRequested: (state) => {
      state.isFetching = true;
      state.error = null;
      state.updated = false;
    },
    userRequestedSuccess: (state, action) => {
      state.entities = action.payload;
      state.dataLoaded = true;
      state.isLoggedIn = true;
      state.isFetching = false;
    },
    userUpdatedSuccess: (state, action) => {
      state.entities = action.payload;
      state.dataLoaded = true;
      state.isLoggedIn = true;
      state.isFetching = false;
      state.updated = "Данные успешно обновлены!";
    },
  },
});

const { reducer: usersReducer, actions } = usersSlice;
const {
  authRequestSuccess,
  authRequestFailed,
  authRequested,
  signupRequested,
  signupRequestSuccess,
  passwordRequested,
  passwordRequestSuccess,
  passwordRequestFailed,
  userRequested,
  userLogout,
  userRequestedSuccess,
  userUpdatedSuccess,
} = actions;

export const signIn = (payload) => async (dispatch) => {
  const { email, password } = payload;
  dispatch(authRequested());
  try {
    const data = await authService.login({ email, password });
    dispatch(authRequestSuccess());
    localStorageService.setTokens(data);
    const user = await userService.get();
    dispatch(userRequestedSuccess(user));
    history.push("/");
  } catch (error) {
    const { message } = error.response.data;
    dispatch(authRequestFailed(message));
  }
};

export const signUp = (payload) => async (dispatch) => {
  dispatch(signupRequested());
  try {
    const data = await authService.register(payload);
    dispatch(signupRequestSuccess());
    return true;
  } catch (error) {
    console.log(error);
    const { message } = error.response.data;
    dispatch(authRequestFailed(message));
  }
};

export const signUpMc = (payload) => async (dispatch) => {
  dispatch(signupRequested());
  try {
    await authService.registerMc(payload);
    dispatch(signupRequestSuccess());
    return true;
  } catch (error) {
    console.log(error);
    const { message } = error.response.data;
    dispatch(authRequestFailed(message));
  }
};

export const signUpInvestor = (payload) => async (dispatch) => {
  dispatch(signupRequested());
  try {
    await authService.registerInvestor(payload);
    dispatch(signupRequestSuccess());
    return true;
  } catch (error) {
    console.log(error);
    const { message } = error.response.data;
    dispatch(authRequestFailed(message));
  }
};

export const recoveryPasswordRequest = (payload) => async (dispatch) => {
  dispatch(passwordRequested());
  try {
    const data = await authService.recoverPassword(payload);
    dispatch(passwordRequestSuccess());
    return true;
  } catch (error) {
    const { message } = error.response.data;
    dispatch(passwordRequestFailed(message));
  }
};

export const resetPassword = (payload) => async (dispatch) => {
  dispatch(passwordRequested());
  try {
    const data = await authService.resetPassword(payload);
    dispatch(passwordRequestSuccess());
    return true;
  } catch (error) {
    const { message } = error.response.data;
    dispatch(passwordRequestFailed(message));
  }
};

export const getLoggedUser = () => async (dispatch) => {
  dispatch(userRequested());
  try {
    const token = localStorageService.getRefreshToken();
    if (token) {
      const user = await userService.get();
      dispatch(userRequestedSuccess(user));
    } else {
      dispatch(userLogout());
    }
  } catch (error) {
    const { message } = error.response.data;
    dispatch(authRequestFailed(message));
  }
};

export const updateUser = (payload) => async (dispatch) => {
  dispatch(userRequested());
  try {
    await userService.update(payload);
    const userUpdated = await userService.get();
    dispatch(userUpdatedSuccess(userUpdated));
    return true;
  } catch (error) {
    const { message } = error.response.data;
    dispatch(authRequestFailed(message));
  }
};

export const updateMcUser = (payload) => async (dispatch) => {
  dispatch(userRequested());
  try {
    await userService.updateMcUser(payload);
    const userUpdated = await userService.get();
    dispatch(userUpdatedSuccess(userUpdated));
    return true;
  } catch (error) {
    const { message } = error.response.data;
    dispatch(authRequestFailed(message));
  }
};

export const updateUserInvestor = (payload) => async (dispatch) => {
  dispatch(userRequested());
  try {
    await userService.updateInvestor(payload);
    const userUpdated = await userService.get();
    dispatch(userUpdatedSuccess(userUpdated));
    return true;
  } catch (error) {
    const { message } = error.response.data;
    dispatch(authRequestFailed(message));
  }
};

export const logOut = () => (dispatch) => {
  localStorageService.removeAuthData();
  dispatch(userLogout());
  history.push("/login");
};

export const downloadFiles = async (file) => {
  const response = await fetch(
    `${config.SERVERLOCAL_URL}api/user/download/${file.id}`,
    {
      headers: {
        Authorization: `Bearer ${localStorageService.getRefreshToken()}`,
      },
    }
  );

  if (response.status === 200) {
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = file.originalName;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
};

export const getCurrentUser = () => (state) => state.users.entities;
export const getUsersFetchingStatus = () => (state) => state.users.isFetching;
export const getAuthErrors = () => (state) => state.users.error;
export const getUserUpdated = () => (state) => state.users.updated;
export const getIsLoggedIn = () => (state) => state.users.isLoggedIn;
export const getDataStatus = () => (state) => state.users.dataLoaded;

export default usersReducer;
