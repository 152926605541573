import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import { useParams } from "react-router-dom";
import {
  getIsLoadingObject,
  getObject,
  loadObject,
} from "../../../store/object";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Carousel from "react-bootstrap/Carousel";
import { headers } from "../../common/tableConfig/objectCard";
import Button from "react-bootstrap/Button";
import { getCurrentUser, getUsersFetchingStatus } from "../../../store/users";
import { select } from "../../../store/selectedItem";
import Spinner from "react-bootstrap/Spinner";
import { openModal } from "../../../store/modal";
import config from "../../../config.json";

const ProfileObject = () => {
  const { objectId } = useParams();
  const object = useSelector(getObject());
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoadingObject());
  const isGlobalLoading = useSelector(getUsersFetchingStatus());
  const [index, setIndex] = useState(0);
  const [coords, setCoords] = useState({ coordinates: null });
  const currentUser = useSelector(getCurrentUser());

  const transformPath = (path) => path.replace("\\", "/");

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleGeocode = (ymaps) => {
    // console.log(ymaps.layer);
    ymaps.geocode(object.address).then((result) => {
      setCoords({
        coordinates: result.geoObjects.get(0).geometry.getCoordinates(),
      });
    });

    // ymaps.addChild(
    //   new YMapDefaultSchemeLayer({
    //     theme: "dark",
    //     customization: [],
    //   })
    // );

    // ymaps.Layer(
    //   "https://core-renderer-tiles.maps.yandex.net/tiles?l=map&theme=dark&%c&%l"
    // );
    // const MAP = "custom#dark";
    // ymaps.layer.storage.add(MAP, function mapLayer() {
    //   return new ymaps.Layer(
    //     "https://core-renderer-tiles.maps.yandex.net/tiles?l=map&theme=dark&%c&%l"
    //   );
    // });
    // ymaps.mapType.storage.add(MAP, new ymaps.MapType("dark", [MAP]));
    // map.setType(MAP);
  };

  const handleInvestment = () => {
    dispatch(
      openModal({
        body: {
          item: { objectId },
          text: `Вы уверены, что хотите подать заявку на инвестирование объекта ${object.title}?`,
        },
        type: "invest",
      })
    );
  };

  useEffect(() => {
    if (object) {
      dispatch(select({ id: objectId, role: "object" }));
    }
  }, [isLoading]);

  if (object && currentUser && !isLoading) {
    const statusObject = object.Investments.filter(
      (inv) => inv.status === "approved"
    );

    return (
      <div className="container-scroll p-2">
        <div className="container-list">
          <Row className="m-0 profile-object-classic">
            <Col className="p-0" xl="4">
              <div className="p-0 position-relative">
                {statusObject.length ? (
                  <div className="d-flex position-absolute justify-content-center align-items-center sale-object soldout">
                    <div className="text-uppercase fs-5 text-light">
                      Sold out
                    </div>
                  </div>
                ) : (
                  <div className="d-flex position-absolute justify-content-center align-items-center sale-object free">
                    <div className="text-uppercase fs-5 text-light">Free</div>
                  </div>
                )}

                <Carousel
                  indicators={false}
                  fade
                  data-bs-theme="dark"
                  activeIndex={index}
                  onSelect={handleSelect}
                >
                  {object.Files.map((obj, i) => {
                    if (obj.fileType === "objectPhoto")
                      return (
                        <Carousel.Item key={obj.id}>
                          <img
                            className="d-block w-100"
                            style={{
                              height: "410px",
                              objectFit: "cover",
                            }}
                            src={`${config.SERVERLOCAL_URL}${obj.path}`}
                            alt={obj.originalName}
                          />
                        </Carousel.Item>
                      );
                  })}
                </Carousel>
                <div
                  className="d-grid"
                  style={{
                    gridTemplateColumns: "repeat(auto-fill,minmax(170px, 1fr))",
                    justifyItems: "center",
                    gap: "8px",
                    marginTop: "8px",
                  }}
                >
                  {object.Files.filter(
                    (item) => item.fileType === "objectPhoto"
                  ).map((obj, i) => (
                    <div
                      key={obj.id}
                      className="d-block"
                      style={{
                        backgroundImage: `url(${
                          config.SERVERLOCAL_URL
                        }${transformPath(obj.path)})`,
                        width: "100%",
                        paddingBottom: "66%",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                      alt={obj.originalName}
                      onClick={() => handleSelect(i)}
                    ></div>
                  ))}
                </div>
              </div>
            </Col>

            <Col className="p-2 py-0" xl="4">
              <Card
                className="border-0 bg-transparent"
                style={{
                  fontSize: "14px",
                  margin: "0 0 8px 0",
                }}
              >
                <Card.Body className="bg-white" style={{ padding: "2px 0" }}>
                  <div
                    className="p-3 ff-demi-22"
                    style={{
                      color: "#CB9722",
                    }}
                  >
                    {object.title}
                  </div>

                  <hr className="p-0 pb-2 m-0" />

                  {headers.map((row) => (
                    <div
                      key={row.header}
                      className={`px-3 d-flex justify-content-between align-items-center ${
                        row.header === "Доходность годовых" && "card-profit"
                      }`}
                      style={{ minHeight: "35px", color: "#343c4d" }}
                    >
                      <div>{row.header}</div>
                      <div
                        className={
                          object[row.body] === null || object[row.body] === ""
                            ? "text-secondary"
                            : ""
                        }
                      >
                        {row.bodyDecorator(object[row.body])}
                      </div>
                    </div>
                  ))}
                </Card.Body>
              </Card>

              {currentUser.role === "investor" && (
                <Button
                  className="w-100 btn-base"
                  style={{ height: "50px", marginBottom: "8px" }}
                  onClick={handleInvestment}
                  disabled={
                    object.status !== "approved" ||
                    object.Investments.some(
                      (inv) => inv.userId === currentUser.id
                    ) ||
                    object.sale === false
                  }
                >
                  Подать заявку на инвестирование
                </Button>
              )}

              <div className="bg-white">
                <div
                  className="p-3 ff-demi-16"
                  style={{
                    color: "#CB9722",
                  }}
                >
                  Юридические документы
                </div>
                <hr className="p-0 m-0" />
                <div
                  className="p-3 d-grid"
                  style={{
                    gridTemplateColumns: "repeat(auto-fill,minmax(120px, 1fr))",
                    justifyItems: "center",
                    gap: "10px",
                  }}
                >
                  {object.Files.map((obj) => {
                    if (obj.fileType === "objectDocs")
                      return (
                        <div
                          key={obj.id}
                          className="d-block"
                          style={{
                            backgroundImage: `url(${
                              config.SERVERLOCAL_URL
                            }${transformPath(obj.path)})`,
                            width: "100%",
                            paddingBottom: "133%",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            cursor: "pointer",
                          }}
                          alt={obj.originalName}
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              `${config.SERVERLOCAL_URL}${transformPath(
                                obj.path
                              )}`,
                              "_blank"
                            );
                          }}
                        ></div>
                      );
                    // return (
                    //   <img
                    //     key={obj.id}
                    //     className="d-block m-1"
                    //     style={{
                    //       height: "160px",
                    //       width: "113px",
                    //       objectFit: "cover",
                    //     }}
                    //     src={`http://localhost:8080/${obj.path}`}
                    //     alt={obj.originalName}
                    //   />
                    // );
                  })}
                </div>
              </div>
            </Col>

            <Col className="p-0" xl="4">
              <YMaps
                query={{
                  apikey: config.API_KEY,
                  lang: "ru_RU",
                }}
              >
                <Map
                  state={{
                    center: coords.coordinates || [55.75, 37.57],
                    zoom: 17,
                  }}
                  width={"100%"}
                  modules={["geocode"]}
                  onLoad={(ymaps) => handleGeocode(ymaps)}
                >
                  <Placemark geometry={coords.coordinates} />
                </Map>
              </YMaps>

              <div
                className="bg-white"
                style={{ marginTop: "8px", whiteSpace: "pre-wrap" }}
              >
                <div
                  className="p-3 ff-demi-16"
                  style={{
                    color: "#CB9722",
                  }}
                >
                  Описание
                </div>
                <hr className="p-0 m-0" />
                <div className="p-3">{object.description}</div>
              </div>
            </Col>
          </Row>

          {/* adaptive 1200px */}
          <Row className="m-0 profile-object-classic-adaptive">
            <Col className="p-0 position-relative" md="6">
              {statusObject.length ? (
                <div className="d-flex position-absolute justify-content-center align-items-center sale-object soldout">
                  <div className="text-uppercase fs-5 text-light">Sold out</div>
                </div>
              ) : (
                <div className="d-flex position-absolute justify-content-center align-items-center sale-object free">
                  <div className="text-uppercase fs-5 text-light">Free</div>
                </div>
              )}
              <Carousel
                data-bs-theme="dark"
                activeIndex={index}
                onSelect={handleSelect}
              >
                {object.Files.map((obj, i) => {
                  if (obj.fileType === "objectPhoto")
                    return (
                      <Carousel.Item key={obj.id}>
                        <img
                          className="d-block w-100"
                          style={{
                            height: "409px",
                            objectFit: "cover",
                          }}
                          src={`${config.SERVERLOCAL_URL}${obj.path}`}
                          alt={obj.originalName}
                        />
                      </Carousel.Item>
                    );
                })}
              </Carousel>
            </Col>

            <Col className="ps-2 pe-0 py-0" md="6">
              <Card
                className="border-0 bg-transparent"
                style={{
                  fontSize: "14px",
                  margin: "0 0 8px 0",
                }}
              >
                <Card.Body className="bg-white" style={{ padding: "2px 0" }}>
                  <div
                    className="p-3 ff-demi-22"
                    style={{
                      color: "#CB9722",
                    }}
                  >
                    {object.title}
                  </div>

                  <hr className="p-0 pb-2 m-0" />

                  {headers.map((row) => (
                    <div
                      key={row.header}
                      className={`px-3 d-flex justify-content-between align-items-center ${
                        row.header === "Доходность годовых" && "card-profit"
                      }`}
                      style={{ minHeight: "35px", color: "#343c4d" }}
                    >
                      <div>{row.header}</div>
                      <div
                        className={
                          object[row.body] === null || object[row.body] === ""
                            ? "text-secondary"
                            : ""
                        }
                      >
                        {row.bodyDecorator(object[row.body])}
                      </div>
                    </div>
                  ))}
                </Card.Body>
              </Card>
            </Col>

            {currentUser.role === "investor" && (
              <Button
                className="w-100 btn-base"
                style={{ height: "50px", marginBottom: "8px" }}
                onClick={handleInvestment}
                disabled={
                  object.status !== "approved" ||
                  object.Investments.some(
                    (inv) => inv.userId === currentUser.id
                  ) ||
                  object.sale === false
                }
              >
                Подать заявку на инвестирование
              </Button>
            )}

            <Col className="p-0" md="12">
              <div
                className="bg-white"
                style={{ marginBottom: "8px", whiteSpace: "pre-wrap" }}
              >
                <div
                  className="p-3 ff-demi-16"
                  style={{
                    color: "#CB9722",
                  }}
                >
                  Описание
                </div>

                <hr className="p-0 m-0" />

                <div className="p-3">{object.description}</div>
              </div>

              {/* <div
                className="d-grid"
                style={{
                  gridTemplateColumns: "repeat(auto-fill,minmax(170px, 1fr))",
                  justifyItems: "center",
                }}
              >
                {object.Files.filter(
                  (item) => item.fileType === "objectPhoto"
                ).map((obj, i) => (
                  <img
                    key={obj.id}
                    className="d-block m-2"
                    style={{
                      height: "113px",
                      width: "170px",
                      objectFit: "cover",
                    }}
                    src={`${config.SERVERLOCAL_URL}${obj.path}`}
                    alt={obj.originalName}
                    onClick={() => handleSelect(i)}
                  />
                ))}
              </div> */}

              <div className="bg-white" style={{ marginBottom: "8px" }}>
                <div
                  className="p-3 ff-demi-16"
                  style={{
                    color: "#CB9722",
                  }}
                >
                  Юридические документы
                </div>

                <hr className="p-0 m-0" />

                <div
                  className="p-3 d-grid"
                  style={{
                    gridTemplateColumns: "repeat(auto-fill,minmax(120px, 1fr))",
                    justifyItems: "center",
                    gap: "10px",
                  }}
                >
                  {object.Files.map((obj) => {
                    if (obj.fileType === "objectDocs")
                      return (
                        <div
                          key={obj.id}
                          className="d-block"
                          style={{
                            backgroundImage: `url(${
                              config.SERVERLOCAL_URL
                            }${transformPath(obj.path)})`,
                            width: "100%",
                            paddingBottom: "133%",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            cursor: "pointer",
                          }}
                          alt={obj.originalName}
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              `${config.SERVERLOCAL_URL}${transformPath(
                                obj.path
                              )}`,
                              "_blank"
                            );
                          }}
                        ></div>
                      );
                  })}
                </div>
              </div>

              <YMaps
                query={{
                  apikey: config.API_KEY,
                  lang: "ru_RU",
                }}
              >
                <Map
                  state={{
                    center: coords.coordinates || [55.75, 37.57],
                    zoom: 17,
                  }}
                  width={"100%"}
                  modules={["geocode"]}
                  onLoad={(ymaps) => handleGeocode(ymaps)}
                >
                  <Placemark geometry={coords.coordinates} />
                </Map>
              </YMaps>
            </Col>
          </Row>

          {/* adaptive 767px*/}
          <Row className="m-0 profile-object-classic-mobile">
            <Col className="p-0">
              <Card
                className="border-0 bg-transparent"
                style={{
                  fontSize: "14px",
                  margin: "0 0 8px 0",
                }}
              >
                <Card.Body className="bg-white" style={{ padding: "2px 0" }}>
                  <div
                    className="p-3 ff-demi-22"
                    style={{
                      color: "#CB9722",
                    }}
                  >
                    {object.title}
                  </div>

                  <hr className="p-0 pb-2 m-0" />

                  {headers.map((row) => (
                    <div
                      key={row.header}
                      className={`px-3 d-flex justify-content-between align-items-center ${
                        row.header === "Доходность годовых" && "card-profit"
                      }`}
                      style={{ minHeight: "35px", color: "#343c4d" }}
                    >
                      <div>{row.header}</div>
                      <div
                        className={
                          object[row.body] === null || object[row.body] === ""
                            ? "text-secondary"
                            : ""
                        }
                      >
                        {row.bodyDecorator(object[row.body])}
                      </div>
                    </div>
                  ))}
                </Card.Body>
              </Card>

              {currentUser.role === "investor" && (
                <Button
                  className="w-100 btn-base"
                  style={{ height: "50px", marginBottom: "8px" }}
                  onClick={handleInvestment}
                  disabled={
                    object.status !== "approved" ||
                    object.Investments.some(
                      (inv) => inv.userId === currentUser.id
                    ) ||
                    object.sale === false
                  }
                >
                  Подать заявку на инвестирование
                </Button>
              )}
            </Col>

            <Col className="p-0" md="12">
              <div
                className="bg-white"
                style={{ marginBottom: "8px", whiteSpace: "pre-wrap" }}
              >
                <div
                  className="p-3 ff-demi-16"
                  style={{
                    color: "#CB9722",
                  }}
                >
                  Описание
                </div>
                <hr className="p-0 m-0" />
                <div className="p-3">{object.description}</div>
              </div>

              <div className="position-relative rounded-1">
                {statusObject.length ? (
                  <div className="d-flex position-absolute justify-content-center align-items-center sale-object soldout">
                    <div className="text-uppercase fs-5 text-light">
                      Sold out
                    </div>
                  </div>
                ) : (
                  <div className="d-flex position-absolute justify-content-center align-items-center sale-object free">
                    <div className="text-uppercase fs-5 text-light">Free</div>
                  </div>
                )}

                <Carousel
                  data-bs-theme="dark"
                  activeIndex={index}
                  onSelect={handleSelect}
                  style={{ marginBottom: "8px" }}
                >
                  {object.Files.map((obj, i) => {
                    if (obj.fileType === "objectPhoto")
                      return (
                        <Carousel.Item key={obj.id}>
                          <img
                            className="d-block w-100"
                            style={{
                              height: "400px",
                              objectFit: "cover",
                            }}
                            src={`${config.SERVERLOCAL_URL}${obj.path}`}
                            alt={obj.originalName}
                          />
                        </Carousel.Item>
                      );
                  })}
                </Carousel>
              </div>

              <div className="bg-white" style={{ marginBottom: "8px" }}>
                <div
                  className="p-3 ff-demi-16"
                  style={{
                    color: "#CB9722",
                  }}
                >
                  Юридические документы
                </div>

                <hr className="p-0 m-0" />

                <div
                  className="p-3 d-grid"
                  style={{
                    gridTemplateColumns: "repeat(auto-fill,minmax(100px, 1fr))",
                    justifyItems: "center",
                    gap: "10px",
                  }}
                >
                  {object.Files.map((obj) => {
                    if (obj.fileType === "objectDocs")
                      return (
                        <div
                          key={obj.id}
                          className="d-block"
                          style={{
                            backgroundImage: `url(${
                              config.SERVERLOCAL_URL
                            }${transformPath(obj.path)})`,
                            width: "100%",
                            paddingBottom: "133%",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            cursor: "pointer",
                          }}
                          alt={obj.originalName}
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              `${config.SERVERLOCAL_URL}${transformPath(
                                obj.path
                              )}`,
                              "_blank"
                            );
                          }}
                        ></div>
                      );
                  })}
                </div>
              </div>

              <YMaps
                query={{
                  apikey: config.API_KEY,
                  lang: "ru_RU",
                }}
              >
                <Map
                  state={{
                    center: coords.coordinates || [55.75, 37.57],
                    zoom: 17,
                  }}
                  width={"100%"}
                  modules={["geocode"]}
                  onLoad={(ymaps) => handleGeocode(ymaps)}
                >
                  <Placemark geometry={coords.coordinates} />
                </Map>
              </YMaps>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  return (
    <>
      {!isGlobalLoading && (
        <div className="container-scroll">
          <div
            className="d-flex container-form m-auto p-0 pt-2"
            style={{
              width: "calc(100vw - 40px)",
            }}
          >
            <Spinner
              className="justify-content-center m-auto"
              animation="border"
              variant="dark"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileObject;
