import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { ReactComponent as FileResetIcon } from "../../../icons/x-circle.svg";
import { ReactComponent as Load } from "../../../icons/load.svg";
import { ReactComponent as File } from "../../../icons/file-icon.svg";
import "../../../App.css";
import * as formik from "formik";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import YupPassword from "yup-password";
import ErrorTooltip from "../../common/ErrorTooltip";
import {
  closeModal,
  getIsModalStatus,
  getTypeModal,
} from "../../../store/modal";
import { getTypeProjectList } from "../../../store/enum";
import { optionsTypeProject } from "../../../constant/constant";
import { checkChangeData, updatedData } from "../../../utils/changeData";
import { getUsersFetchingStatus, updateMcUser } from "../../../store/users";
import { normalizeFiles } from "../../../utils/normalizeData";
YupPassword(yup);

const ProfileMc = ({ currentUser }) => {
  const { email, phone, name, telegram, ManagementCompany: mc } = currentUser;
  const options = useSelector(getTypeProjectList());
  const isActiveModal = useSelector(getIsModalStatus());
  const typeModal = useSelector(getTypeModal());
  const isLoading = useSelector(getUsersFetchingStatus());
  const dispatch = useDispatch();

  const { Formik } = formik;
  const initialValues = {
    nameCompany: mc.nameCompany,
    inn: mc.inn,
    site: mc.site,
    presentation: mc.Files,
    typeProject: optionsTypeProject(mc.typeProject),
    name,
    email,
    phone,
    employeePosition: mc.employeePosition,
    numberProject: String(mc.numberProject),
    telegram: telegram || "",
    about: mc.about || "",
  };

  const schema = yup.object().shape({
    nameCompany: yup.string().required("Введите название компании"),
    inn: yup
      .string()
      .test(
        "numbers",
        "Допустимы только цифры",
        (value) => !value || /^[0-9]+$/.test(value)
      )
      .required("Введите ИНН"),
    site: yup.string().required("Введите сайт компании"),
    email: yup
      .string()
      .required("Введите эл. почту")
      .email("Введите эл. почту"),
    phone: yup
      .string()
      .required("Введите номер телефона")
      .password()
      .min(11, "Номер должен начинаться с 8")
      .max(11, "Некорректный номер")
      .minNumbers(11, "Пароль должен состоять из цифр")
      .minSymbols(0)
      .minLowercase(0, "Пароль должен содержать хотя бы 1 строчную букву")
      .minUppercase(0, "Пароль должен содержать хотя бы 1 заглавную букву"),
    presentation: yup.mixed().required("Загрузите файл"),
    employeePosition: yup.string().required("Введите должность представителя"),
    name: yup.string().required("Введите ФИО"),

    typeProject: yup
      .array()
      .required("Введите тип проекта")
      .test("len", "Выберите тип проектов", (arr) => arr.length > 0),

    // .test("len", "Выберите тип проектов", (arr) => arr.length > 0)
    // .test("is-null", "Выберите тип проектов", (arr) => arr !== null),
    numberProject: yup
      .string()
      .test(
        "numbers",
        "Допустимы только цифры",
        (value) => !value || /^[0-9]+$/.test(value)
      )
      .required("Введите число проектов"),
  });

  const handleSave = (data) => {
    dispatch(updateMcUser(data));
  };

  const handleClose = () => {
    dispatch(closeModal());
  };

  if (options && currentUser && !isLoading)
    return (
      <Modal
        dialogClassName="modal-profile-mc"
        size="xl"
        show={isActiveModal && typeModal === "profile"}
        onHide={handleClose}
        animation={false}
        style={{ color: "#343c4d", fontFamily: "Futura PT Demi" }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              fontFamily: "Futura PT Heavy",
              color: "#CB9722",
            }}
          >
            Редактирование профиля УК
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <Formik
            validationSchema={schema}
            onSubmit={async (values) => {
              const isReset = await handleSave({
                ...updatedData(values, initialValues),
                email: values.email,
                phone: values.phone,
              });

              if (isReset) {
                dispatch(closeModal());
              }
            }}
            // onSubmit={(values) =>
            //   console.log(updatedData(values, initialValues))
            // }
            initialValues={initialValues}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldValue,
            }) => (
              <Form
                className="d-flex justify-content-center flex-column"
                onSubmit={handleSubmit}
              >
                <div>
                  <Form.Group className="mx-0" as={Row}>
                    <Form.Label className="m-auto p-0" column sm="4" lg="2">
                      Ваш ID
                    </Form.Label>
                    <Col sm="8" lg="10">
                      <Form.Control
                        plaintext
                        readOnly
                        defaultValue={`#${currentUser.id}`}
                        style={{ height: "42px", color: "#CB9722" }}
                      />
                    </Col>
                  </Form.Group>

                  <Row>
                    <Col lg="6">
                      <Form.Group as={Row} className="mx-0 mb-3">
                        <Form.Label className="m-auto p-0" column sm="4">
                          Название
                        </Form.Label>
                        <Col className="position-relative">
                          <Form.Control
                            type="text"
                            name="nameCompany"
                            value={values.nameCompany}
                            onChange={handleChange}
                            className="input-object"
                          />
                          <ErrorTooltip children={errors.nameCompany} />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mx-0 mb-3">
                        <Form.Label className="m-auto p-0" column sm="4">
                          ИНН
                        </Form.Label>
                        <Col className="position-relative">
                          <Form.Control
                            type="text"
                            name="inn"
                            value={values.inn}
                            onChange={handleChange}
                            className="input-object"
                          />
                          <ErrorTooltip children={errors.inn} />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mx-0 mb-3">
                        <Form.Label className="m-auto p-0" column sm="4">
                          Сайт компании
                        </Form.Label>
                        <Col className="position-relative">
                          <Form.Control
                            type="text"
                            name="site"
                            value={values.site}
                            onChange={handleChange}
                            className="input-object"
                          />
                          <ErrorTooltip children={errors.site} />
                        </Col>
                      </Form.Group>

                      <Form.Group
                        as={Row}
                        className="mx-0 mb-3"
                        controlId="validationFormik05"
                      >
                        <Form.Label className="m-auto p-0" column sm="4">
                          Презентация
                        </Form.Label>
                        <Col className="position-relative">
                          <Button
                            variant="link"
                            className="w-75 position-relative btn-load p-0"
                            style={{ color: "#cb9722", height: "45px" }}
                            title=""
                          >
                            <div
                              className="position-absolute top-50 start-50 translate-middle"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            >
                              <Load /> Загрузить презентацию
                            </div>
                            <Form.Control
                              type="file"
                              name="presentation"
                              style={{ opacity: "0" }}
                              onChange={(event) => {
                                setFieldValue(
                                  "presentation",
                                  normalizeFiles(event.target.files)
                                );
                                document.getElementById(
                                  "validationFormik05"
                                ).value = "";
                              }}
                            />
                            <ErrorTooltip children={errors.presentation} />
                          </Button>

                          {values.presentation !== "" && (
                            <div
                              className="d-inline-block position-relative ms-2"
                              title={`${
                                values.presentation[0].name ||
                                values.presentation[0].originalName
                              }`}
                            >
                              {/* <FileIcon /> */}
                              <File />
                              <div
                                className="position-absolute ff-demi"
                                style={{
                                  fontSize: "10px",
                                  bottom: "10px",
                                  left: "10px",
                                }}
                              >
                                {values.presentation[0].type.split("/")[1]}
                              </div>
                              <Button
                                className="position-absolute translate-middle badge rounded-pill border-0"
                                style={{
                                  top: "18%",
                                  right: "-20%",
                                  backgroundColor: "#cb9722",
                                  padding: "0px",
                                }}
                                onClick={() => {
                                  setFieldValue("presentation", "");
                                }}
                              >
                                <FileResetIcon />
                              </Button>
                            </div>
                          )}
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mx-0 mb-3">
                        <Form.Label className="m-auto p-0" column sm="4">
                          ФИО
                        </Form.Label>
                        <Col className="position-relative">
                          <Form.Control
                            type="text"
                            name="name"
                            placeholder="Введите ФИО"
                            value={values.name}
                            onChange={handleChange}
                            className="input-object"
                          />
                          <ErrorTooltip children={errors.name} />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mx-0 mb-3">
                        <Form.Label className="m-auto p-0" column sm="4">
                          Тип проекта
                        </Form.Label>
                        <Col className="position-relative">
                          <Select
                            placeholder="Выбрать типы проектов"
                            isMulti
                            defaultValue={values.typeProject}
                            name="typeProject"
                            options={options}
                            onChange={(data) => {
                              setFieldValue(
                                "typeProject",
                                data.map((item) => item.label)
                              );
                            }}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            styles={{
                              menu: (styles) => ({
                                ...styles,
                                zIndex: "3",
                              }),
                              indicatorsContainer: (styles) => ({
                                ...styles,
                              }),
                              control: (styles) => ({
                                ...styles,
                                minHeight: "48px",
                                borderRadius: "0",
                                backgroundColor: "rgb(7, 3, 34, 0.05)",
                                borderColor: "transparent",
                                outline: "none",
                                boxShadow: "none",

                                ":hover": {
                                  border: "2px solid rgb(203, 151, 34, 0.4)",
                                  cursor: "pointer",
                                },
                                ":active": {
                                  borderColor: "rgb(203, 151, 34, 0.4)",
                                  cursor: "pointer",
                                },
                              }),
                              placeholder: (styles) => ({
                                ...styles,
                                color: "#343c4d",
                                opacity: "0.7",
                                fontFamily: "Futura PT Demi",
                                fontSize: "16px",
                              }),
                            }}
                          />
                          <ErrorTooltip children={errors.typeProject} />
                        </Col>
                      </Form.Group>
                    </Col>

                    <Col lg="6">
                      <Form.Group as={Row} className="mx-0 mb-3">
                        <Form.Label className="m-auto p-0" column sm="4">
                          Email
                        </Form.Label>
                        <Col className="position-relative">
                          <Form.Control
                            type="text"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            className="input-object"
                          />
                          <ErrorTooltip children={errors.email} />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mx-0 mb-3">
                        <Form.Label className="m-auto p-0" column sm="4">
                          Телефон
                        </Form.Label>
                        <Col className="position-relative">
                          <Form.Control
                            type="text"
                            name="phone"
                            value={values.phone}
                            onChange={handleChange}
                            className="input-object"
                          />
                          <ErrorTooltip children={errors.phone} />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mx-0 mb-3">
                        <Form.Label className="m-auto p-0" column sm="4">
                          Должность
                        </Form.Label>
                        <Col className="position-relative">
                          <Form.Control
                            type="text"
                            name="employeePosition"
                            value={values.employeePosition}
                            onChange={handleChange}
                            className="input-object"
                          />
                          <ErrorTooltip children={errors.employeePosition} />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mx-0 mb-3">
                        <Form.Label className="m-auto p-0" column sm="4">
                          Успешных проектов
                        </Form.Label>
                        <Col className="position-relative">
                          <Form.Control
                            type="text"
                            name="numberProject"
                            value={values.numberProject}
                            onChange={handleChange}
                            className="input-object"
                          />
                          <ErrorTooltip children={errors.numberProject} />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mx-0 mb-3">
                        <Form.Label className="m-auto p-0" column sm="4">
                          Telegram
                        </Form.Label>
                        <Col>
                          <Form.Control
                            type="text"
                            name="telegram"
                            placeholder="Введите @telegram"
                            value={values.telegram}
                            onChange={handleChange}
                            className="input-object"
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mx-0 mb-3">
                        <Form.Label className="m-auto p-0" column sm="4">
                          О компании
                        </Form.Label>
                        <Col>
                          <Form.Control
                            as="textarea"
                            rows={5}
                            name="about"
                            placeholder="Расскажите о компании"
                            value={values.about}
                            onChange={handleChange}
                            className="input-object"
                            style={{
                              height: "150px",
                              whiteSpace: "pre-line",
                              resize: "none",
                            }}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>

                <Button
                  type="submit"
                  className="m-auto my-4 btn-base"
                  disabled={!checkChangeData(values, initialValues)}
                  style={{ width: "230px", height: "62px" }}
                >
                  Сохранить
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
};

export default ProfileMc;
